/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


// Soft UI Dashboard PRO React components


// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ruv.png";

import { ProjekteContextProvider } from "globalState";

// import PrivateRoute from 'privateroute';







// element={<PrivateRoute>{route.component}</PrivateRoute>}

 
export default function App() {
  


  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const sess_key = sessionStorage.getItem("session_key");
  const [sessionIsInvalid, setSessionIsInvalid] = useState(false);
 
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  
  

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  
  
  // useEffect(() => {
  //   // Check if session key exists in local storage or cookies
  //   const sessionKey = localStorage.getItem('sessionKey');
  //   if (sessionKey) {
  //     // Check if session key has expired
  //     const expirationTime = 900000;
  //     const currentTime = new Date().getTime() / 1000; // Current time in seconds
  //     if (expirationTime < currentTime) {
  //       // Session key has expired
  //       setSessionIsInvalid(true);
  //       sessionStorage.clear();
  //     } 
  //   }
  // }, []);


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
   
      if (route.route) {
        return <Route exact path={route.route} 
        element={route.component}    
         key={route.key} />
      
      }
      return null;
    });
   
   

  return direction === "rtl" ? (
        <ProjekteContextProvider>
          <CacheProvider value={rtlCache}>
            <ThemeProvider theme={themeRTL}>
              <CssBaseline />
              {layout === "login" && (
                <>
                   <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName="R+V Vertriebstool Firmenversorgungskonzepte"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  /> 
                  <Configurator />
                </>
              )}
              {layout === "vr" && <Configurator />}
              <Suspense fallback={<div></div>}>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              </Suspense>
            </ThemeProvider>
          </CacheProvider>
        </ProjekteContextProvider>
  

  
  ) : (

        <ProjekteContextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline /> 
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Firmenversorgung"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />  
                <Configurator />
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Suspense fallback={<div></div>}>
            <Routes>
              {getRoutes(routes)}
                {/* <Route path="*" element={<Login/>} /> */}
                <Route path="*" element={true?<Navigate to="/hooks/authentication" />: null} />  
            </Routes>
            </Suspense>
          </ThemeProvider>
        </ProjekteContextProvider>


  );
}
